<template>
  <div class="new-employee panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">Создание нового сотрудника</h1>
    <form class="box" @submit.prevent="submit">
      <div class="columns is-multiline">
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Фамилия"
            :type="{
              'is-danger': $v.lastName.$error || 'lastName' in serverValid,
              'is-success': !$v.lastName.$invalid && $v.lastName.$dirty,
            }"
            :message="{ 'Некорректно указана фамилия': $v.lastName.$error }"
          >
            <b-autocomplete
              placeholder="Фамилия"
              :data="lastNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="lastName"
              @typing="getDadata($event, 'lastName')"
              @select="setGender"
              @blur="
                onTouchField('lastName');
                removeKeyFromServerValid('lastName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Имя"
            :type="{
              'is-danger': $v.firstName.$error || 'firstName' in serverValid,
              'is-success': !$v.firstName.$invalid && $v.firstName.$dirty,
            }"
            :message="{ 'Некорректно указано имя': $v.firstName.$error }"
          >
            <b-autocomplete
              placeholder="Имя"
              :data="firstNameData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="firstName"
              @typing="getDadata($event, 'firstName')"
              @select="setGender"
              @blur="
                onTouchField('firstName');
                removeKeyFromServerValid('firstName');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Отчество"
            :type="{
              'is-danger': $v.patronymic.$error || 'patronymic' in serverValid,
              'is-success': !$v.patronymic.$invalid && $v.patronymic.$dirty,
            }"
            :message="{ 'Некорректно указано отчество': $v.patronymic.$error }"
          >
            <b-autocomplete
              placeholder="Отчество"
              :data="patronymicData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="patronymic"
              @typing="getDadata($event, 'patronymic')"
              @select="setGender"
              @blur="
                onTouchField('patronymic');
                removeKeyFromServerValid('patronymic');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field label="Пол">
            <b-radio-button
              v-model="gender"
              native-value="0"
              type="is-primary"
              expanded
            >
              <b-icon icon="male" size="is-medium"></b-icon>
              <span>Мужской</span>
            </b-radio-button>

            <b-radio-button
              v-model="gender"
              native-value="1"
              type="is-primary"
              expanded
            >
              <b-icon icon="female" size="is-medium"></b-icon>
              <span>Женский</span>
            </b-radio-button>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Телефон"
            :type="{
              'is-danger': $v.phone.$error || 'phone' in serverValid,
              'is-success': !$v.phone.$invalid && $v.phone.$dirty,
            }"
            :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
          >
            <IMaskComponent
              placeholder="+7(900)000-00-00"
              maxlength="16"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.phone.$error || 'phone' in serverValid,
                'is-success': !$v.phone.$invalid && $v.phone.$dirty,
              }"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim="phone"
              @blur="
                onTouchField('phone');
                removeKeyFromServerValid('phone');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Роль сотрудника"
            :type="{
              'is-danger': $v.role.$error || 'role' in serverValid,
              'is-success': !$v.role.$invalid && $v.role.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.role.$error }"
          >
            <b-select
              placeholder="Выберите роль сотрудника"
              expanded
              v-model="role"
            >
              <option value="2">Администратор</option>
              <option value="3">Сотрудник</option>
            </b-select>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Email"
            :type="{
              'is-danger': $v.email.$error || 'email' in serverValid,
              'is-success': !$v.email.$invalid && $v.email.$dirty,
            }"
            :message="{ 'Некорректно указан email': $v.email.$error }"
          >
            <b-input
              placeholder="example@mail.com"
              v-model.trim="email"
              @blur="
                onTouchField('email');
                removeKeyFromServerValid('email');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Пароль"
            :type="{
              'is-danger': $v.password.$error || 'password' in serverValid,
              'is-success': !$v.password.$invalid && $v.password.$dirty,
            }"
            :message="{ 'Некорректно указан пароль': $v.password.$error }"
          >
            <b-input
              type="password"
              placeholder=""
              :lazy="true"
              v-model.trim="$v.password.$model"
              password-reveal
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button
          native-type="submit"
          type="is-success"
          expanded
          icon-left="user-plus"
          >Создать</b-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';

export default {
  name: 'NewEmployee',
  data() {
    return {
      lastNameData: [],
      firstNameData: [],
      patronymicData: [],
      isFetching: false,
      firstName: '',
      lastName: '',
      patronymic: '',
      gender: '',
      phone: '',
      email: '',
      password: '',
      role: null,
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    noValid() {
      return this.$store.state.employee.noValid;
    },
  },
  watch: {
    noValid(fields) {
      this.serverValid = { ...fields };
    },
  },
  methods: {
    setGender(option) {
      if (option !== null) {
        this.gender =
          option.data.gender !== 'UNKNOWN'
            ? option.data.gender === 'MALE'
              ? '0'
              : '1'
            : this.gender;
      }
    },
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = await DadataAPI.getFIO({
        value,
        type: field,
        gender: this.gender,
      });

      this[field + 'Data'] = [];

      if (data.length) {
        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const employeeData = {
        firstName: this.firstName,
        lastName: this.lastName,
        patronymic: this.patronymic,
        gender: this.gender,
        phone: this.phone,
        email: this.email,
        password: this.password,
        role: this.role,
      };

      this.$store.dispatch('employee/newEmployee', employeeData);
    },
  },
  validations: {
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    patronymic: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    role: {
      required,
    },
    phone: {
      minLength: minLength(16),
      maxLength: maxLength(16),
      required,
    },
    email: {
      required,
      email,
      maxLength: maxLength(40),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.new-employee {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;

      button {
        max-width: 140px;
      }
    }
  }
}
</style>
